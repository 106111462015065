:root {
  --hti-magenta: #aa1572;
  --hti-title: #711865;
  --hti-pink: #c85b6e;
  --hti-gradient : linear-gradient(180deg, #B23C51 0%, #711865 100%);
  --hti-purple-70 : #71186570;
  --hti-brick : #B23C51;
  --hti-hover :  #F1E7EF;
  --glph-color : #ED572E;
  --queued: #AA1572;
  --in-progress: #5d1052;
  --processed: #27AE60;
  --failed: #EB5757;
  --completed: #2D9CDB;
  --Intervention: #f2994a;
  --uploading: #4F4F4F;
  --uploaded: #9B51E0;
  
}

/*================overriding Mui styles=============*/
.MuiInput-underline:after{
  border-bottom: none !important;
  border-color: black !important;
  color : black !important;
}
.Mui-focused{
  color : black;
  border-color:rgb(145, 143, 143)!important;
}

.Mui-focused fieldset{
  /*border-color: rgb(145, 143, 143)!important;*/
  border : 1px solid black!important;
}
.MuiPaper-elevation8 {
  box-shadow:  0px 0px 4px 1px rgba(0,0,0,0.14), 0px 3px 5px 2px rgba(0,0,0,0.12) !important;
}
.MuiSnackbarContent-root {
  background-color: var(--hti-title) !important;
}
.MuiSelect-select:hover::after{
  background-color: grey !important;
}
.MuiSelect-select option:hover{
  background-color: red !important;
}
.MuiOutlinedInput-multiline{
  padding: 10px 5px !important;
}
.MuiAutocomplete-listbox{
  max-height: 15em !important;
}
.MuiTab-root.Mui-selected{
  color: var(--hti-magenta) !important;
}
.MuiTabs-indicator{
  background-color: var(--hti-magenta) !important;
}
.EHR .MuiDialog-paper{
  max-width: 75vw !important;
  width: 75vw !important;
  height: 90vh !important;
}
.EHR .MuiDialogContent-root{
  margin: 3px !important;
  padding: 0px !important;
}
.MuiCheckbox-colorPrimary.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
}
/*=================================================*/
.block-area-common {
  opacity: 0.5;
  pointer-events: none;
}

.common-spinner{
  color : #aa1572 !important;
  position: absolute !important;
  margin: 40vh auto;
  z-index: 1;
}
.mini-spinner{
  color : #aa1572 !important;
  margin: 10px;
}
.apply-button:focus{
  outline: none !important;
}
.reset-button:focus{
  outline:  none !important;
  
}
.reset-button {
  width : 90px;
  height: 32px;
  border: 1px solid var(--hti-magenta);
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  background-color: transparent;
}
.reset-button:hover {
  background-color: #F1E7EF;
}
.apply-button-text {
  margin : 0px;
  color : white;
}

.reset-button-text{
    margin : 0px;
    color: var(--hti-title);

}

.App {
  /*height: 100%;*/
  text-align: center; 
}

.flex-style {
  display: flex;
  flex-flow: wrap;
}

.flex-margin {
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
  margin: 10px;
}
.flex-justify {
  display: flex;
  justify-content: space-around;
  flex-flow: wrap;
}
.flex-space{
  display: flex;
  justify-content: space-between;
  align-items: center;
  
}
.audit-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.title {
  font-size: 1.2em;
  line-height: 23px;
  text-align: center;
  color: var(--hti-title);
  margin: 0px;
}
.sub-title {
  font-size: 1em;
  line-height: 1.2em;
  text-align: center;
  color: var(--hti-title);
  margin: 0px;
  
}
.page-title {
  font-weight: 300;
  font-size: 1.5em;
  line-height: 1.7em;
  color: #4f4f4f;
  margin: 0px;
  display: flex;
}

.titlebox {
  text-align: left;
}
.page-sub-title {
  font-weight: 300;
  font-size: 1em;
  line-height: 1.2em;
  margin : 0px;
  /* txtgrey */

  color: #4f4f4f;
}
.dashboardTitle {
  padding: 15px 0px 0px 10px;
  color: var(--hti-title);
  font-size: 1.2em !important;
  text-align: left;
}
.Entry {
  position: absolute;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  background: radial-gradient(
      63.28% 63.28% at 50% 61.94%,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    rgba(48, 68, 93, 0.42);
}

.mini-text {
 
  line-height: 1.2em;
  text-align: center;
  margin: 0px;
  color: #000000;
}

.doc-id {
  color: var(--hti-magenta);
  font-size: 0.7em;
  line-height: 16px;
  text-align: center;
  margin: 0px;
  
}

.doc-cat {
  color: #4f4f4f;
  font-size: 0.9em;
  line-height: 16px;
  text-align: center;
  margin: 0px;
}
.idStyle {
  color: var(--hti-magenta) !important;
  text-decoration: underline;
  cursor: pointer;
}
.horizontal-line {
  opacity: 0.4;
  margin: 1em 50px 1% 50px;
  border: 1px solid var(--hti-magenta);
}
.sidebar {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;

  transition: 0.5s;
}
.only-flex {
  display: flex;
  margin-top: 60px;
}

.searchbox {
  justify-content: space-between;
  margin-bottom: 10px;
}
.link-style {
  color : var(--hti-magenta);
  cursor: pointer;

}
.link-style:hover {
  text-decoration: underline;
}
.locations-box {
  width: 508px;
  height: 200px;
  border: 1px solid #bdbdbd;
  border-radius: 4px;
  margin-top: 10px;
  overflow: hidden;
  padding: 10px 0 0 15px;
  display: flex;
  flex-direction: column;
}

.locations-box:hover {
  overflow-y: scroll;
}

.locations-box label span {
  font-size: 14px;
}

.locations-box .MuiSvgIcon-root {
  width: 0.8em;
  height: 0.8em;
}

.searchbox .apply-button {
  margin: 0;  
}

.MuiFormHelperText-contained {
  margin-left: 0 !important;
}

.userprofilepage {
  display: flex;
  flex-direction: column;
  padding: 15px;
  margin-top: 60px;
}

.usermgmtpage {
  padding: 15px;
  margin-top: 60px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.usermgmtpage table td {
  font-size: 14px;
}

.filterHeadStyle {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  background-color: var(--hti-magenta);
  color: white;
}

.buttonTheme {
  background-color: var(--hti-magenta) !important;
  color: white !important;
}

.buttonAudit {
  padding: 10px;
  /* display: initial; */
  justify-content: space-between;
  display: flex;
}

.tableCellStyle {
  padding: 5px !important;
 }
.tableHeadStyle {
  font-size: 0.9em !important;
  padding: 7px !important;
  cursor : pointer;
  background-color: #f5f5f5 !important;
}
.p-style {
  margin: 0px;
  padding: 0px;
}
.icon-text {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.doc-image {
  width: 90%;
  margin: 0.3em;
  height: 50%;
}
.icon-action {
  cursor: pointer;
  opacity: 0.7 !important;
}
.icon-action:hover {
  
  opacity: initial !important;
}

.icon-action-edit {
  cursor: pointer ;
  color : var(--hti-magenta);
 
}
.status-icon {
  display: flex;
  align-items: center;
}
.negative-button:hover {

    background-color: #ececec;
    border-radius: 4px;
    cursor: pointer;
}
.search-native-input{
  height: 28px;
  border-style: none;
  border-radius: 3px;
  margin: 5px;
  width:95%
  

}
.search-native-input:focus {
  border-style: none;
  outline-color: white;
  
}

.doc-name-blur {
  color: rgb(162, 160, 160);
  cursor : default;
  margin : 0px;
}
.doc-name-focus{
  margin : 0px;
  color: rgba(0, 0, 0, 0.87);
}
.doc-name-focus:hover {
  text-decoration: underline;
  cursor : pointer;
}
.glph-icon {
  font-size: 0.8em !important;
  color : var(--glph-color);
}
.icon-show {
  visibility: visible;
}
.icon-hide {
  visibility: hidden;
}
.block-cursor {
  cursor : not-allowed !important;
}
.block-mouse-events{
  pointer-events: none !important;
}
.block-events-cursor{
  cursor: not-allowed !important;
  pointer-events: none !important;
}
/*================webkit scrollbar ====================*/
::-webkit-scrollbar {
  width: 7px;
  height : 10px;
}

/*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 4px rgba(0,0,0,0.3); 

}*/

::-webkit-scrollbar-thumb {
 
  -webkit-box-shadow: inset 0 0 8px rgba(0,0,0,0.4); 
  border-radius: 5px;
  
}

.PrivateRadioButtonIcon-checked-11 {
  color: var(--hti-magenta);
}


	.full-table{
		max-height : 120vh;
  }

.MuiRadio-colorSecondary.Mui-checked {
  color: var(--hti-magenta) !important;
}


/*============== CSS to display icons/strips to indicate who else is on the document========*/

.blue-strip {
  height: 25px;
  border: 1px solid #2D9CDB;
  background-color: #2D9CDB;
  width: 75%;
  margin: 0 auto;
  border-radius: 0 0 50px 50px;
}
.red-strip {
  height: 25px;
  border: 1px solid #FF6A6A;
  background-color: #FF6A6A;
  width: 75%;
  margin: 0 auto;
  border-radius: 0 0 50px 50px;
}
.file-lock-icon{
  display: flex;
  align-items: center;
  margin-right: 3px;
  cursor: pointer;
}
.file-icon-hide{
  visibility: hidden;
   margin-right: 3px;
 
}
.blue-icon {
  color : #2D9CDB;
}
.red-icon {
  color : #FF6A6A;
  font-size : 0.9em !important ;
  margin : -7px -5px 0px 0px;
}
.red-icon_1 {
  color : #FF6A6A;
 
}
.flex-inline{
  display: flex;
  align-items: flex-start;
}
.flex-inline-end{
  display: flex;
  align-items: flex-end;
}
/*============Error boundary message=============*/
.error-boundary-msg{
 color : var(--hti-title);
 font-size: 1.5em;
 text-align: center;
 margin-top: 10%;
}

.doc-decoration{
  text-decoration: none;
}
.css-zw3mfo-MuiModal-root-MuiDialog-root {
  z-index: 9999;
}

.client-selection {
  display: flex;
  flex-direction: column;
}

.client-selection > div {
  padding: 15px 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 5px 5px;
  cursor: pointer;
  background: var(--hti-magenta);
  color: #fff;
  display: flex;
  align-items: flex-end;
  font-size: 1.2em !important;
  padding-right: 68px;
}

.client-selection img {
  width: 80px;
  margin-right: 20px;
}

.client-selection .clientlabel {
  font-size: inherit;
}

.client-selection-popup .MuiPaper-root.MuiPaper-elevation {
  height: auto;
}

.client-selection-title {
  margin-bottom: 10px;
  font-size: 1.1rem;
  font-weight: 500;
}

.MuiPickersPopper-root {
  z-index: 1300 !important;
}

.apply-button {
  width: 90px;
  height: 32px;
  background: var(--hti-magenta);
  border-radius: 4px;
  cursor: pointer;
  margin-right: 20px;
  border : none;
  min-width: 80px;
}
.apply-button:hover {
    
    background: var(--hti-gradient);
}

.MuiAutocomplete-endAdornment button {
  padding: 4px !important;
}

.MuiCheckbox-colorPrimary {
  color: var(--hti-magenta) !important;
}

.check-box-color {
  color: var(--hti-magenta)!important;
  padding: 6px!important;
}
.sortSection {
  display: flex;
  align-items: center;
}

.div-center {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: 20px;
}

.icon-review-style {
  color : var(--hti-magenta);
  margin : 5px;
  cursor: pointer;
  opacity: 0.8;

}
.icon-review-style:hover {
  opacity: initial;
}
.icon-disable {
  color : lightgray;
  cursor : auto;
  margin : 5px;
}
.export-report-link {
  color: #aa1572!important;
  text-decoration: none!important;
  margin-right: 10px;
}

.rp-print-section, .rp-print-section > span {
  display: flex;
  align-items: center;
}
.rp-print-section {
  cursor: pointer;
  color: #aa1572;
}

.MuiInputBase-input.MuiOutlinedInput-input {
  padding-top: 10.5px;
  padding-bottom: 10.5px;
}

/* label + .MuiInput-formControl {
  margin-top: 16px;
} */

.suggest-box {
  position: absolute;
  z-index: 200;
  background-color: white;
  width: 300px;
  max-height: 300px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 1px 1px 1px lightgray;   
  box-sizing: border-box;
  transition: all 0.3s ease-out;
  color : black !important;
  overflow: auto;
}
.suggest-result {
  font-size: 0.95em;
  cursor: pointer;
  text-align: left;
  padding: 5px;
  margin : 5px;
}
.suggest-result:hover {
  background: rgba(113, 24, 101, 0.1);
}