.navigation-menu-container {
    max-height: 60px !important;
    min-height: 60px !important;
    display: flex;
    justify-content: space-between;
    left: 0;
    right: 0;
    position: fixed;
    top: 0;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,.44);
    background: linear-gradient(96.29deg, var(--hti-title) -25.11%, var(--hti-pink) 98.84%);
    z-index: 999;
    color: #fff;
    align-items: center;
}

.navigation-menu-container.navigation-menu-container-blue {
    background: linear-gradient(to right, #0575B5, #76bde6);
    box-shadow: rgba(0, 0, 0, 0.44) 0px 3px 6px 0px;
}

.navbar-menu-list {
    display: flex;
  }
  
  .navbar-menu-item {
    padding: 10px 20px !important;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    font-weight: 500 !important;
    font-size: 1.2em !important;
    line-height: 22px !important;
    margin-left: 5px;
    margin-right: 7px;
    cursor: pointer;
    color: #fff !important;
    text-transform: capitalize;
  }
  .navbar-submenu-item {
    /* padding: 10px 20px; */
    font-weight: 500 !important;
    font-size: 1.1em !important;
    line-height: 22px !important;
    margin: 2px 0px !important;
    cursor: pointer;
    color: #000 !important;
    text-transform: capitalize;
    display: flex !important;
    align-items: center;
    justify-content: space-between !important;
  }
  .navbar-menu-item.navbar-menu-item-selected {
    border-bottom: 2px solid;
  }
  .navbar-submenu-item.navbar-submenu-item-selected, .navbar-submenu-item.navbar-submenu-item-selected:hover {
    background-color: var(--hti-magenta);
    color: #fff !important;
    border-radius: 3px;
  }
  
  .navbar-menu-item svg {
    margin-left: 10px;
  }
  .navbar-submenu-item svg {
    margin-left: 20px;
  }
  
  .navbar-menu-item:hover {
    cursor: pointer;
  }

  .logo-grp1 {
    width: 40px;
    height: 40px;
  }
  .rd-nav {
    margin-top : 0.9em;
  }
  .logo-name {
    margin : 0px 20px 0px 15px;
    display: flex;
  }
  .left-grid {
    display: flex;
    align-items: center;
    max-height:  60px;
  }
  .docu-nav {
    font-weight: 500;
    font-size: 1.5em;
    line-height: 28px;
    margin:0px;
    color : white;
  }
  
  .route-nav {
    font-weight: 300;
    font-size: 1.5em;
    line-height: 28px;
    margin:0px;
  }
  .doc-route {
    margin-left: 7px;
  }
  .tab-text {
    font-weight: 500 !important;
    font-size: 1.2em;
    line-height: 22px;
    margin-left : 5px;
    margin-right: 7px;
    cursor: pointer;
    color: #ffffff;
    text-transform: capitalize;
  }
  
  .tab-width {
    width: 5% !important;
  }
  .tabs {
    display: flex;
  }
  
  .right-grid {
    margin-right: 15px;
  }
  .headLinkStyle {
    text-decoration: none !important;
    color: black !important;
  }
  .activeHeadLinkStyle {
    text-decoration: none !important;
  }
  .under-line {
    height: 3px;
    background-color: white;
    margin-top : -20px;
    
  }
  .under-line-hide {
    height: 3px;
    margin-top: -20px;
   
  }
  .under-line-mgmt {
    height: 3px;
    background-color: white;
    margin-top : -20px;
   
  }
  .under-line-mgmt-hide {
    height: 3px;
    margin-top: -20px;
   
  }
  .rectangle {
    position: absolute;
    left: 0.47%;
    right: 5.63%;
    top: 1.61%;
    bottom: 1.61%;
  
    /* white */
  
    background: #ffffff;
    opacity: 0.1;
    /* white */
  
    border: 1px solid #ffffff;
  }
  
  .tab-layout {
    height: 20px;
  }
  #simple-menu .MuiMenuItem-root{
    padding-top : 6px !important;
    padding-bottom: 6px !important;
  }
  
  .user-name-disp {
    color: white;
    margin-right: 7px;
    text-transform: None !important;
    text-align: left;
  }
  .vertical-line-nav {
    width: 0px;
    min-height: 4vh;
    border: 1px solid white;
    background-color: white;
  }
  
  .client-logo {
    padding: 0px 20px;
    border-left: 1px solid #fff;
  }
  
  .client-selection-menu {
    border-top: 1px solid #ccc;
  }
  
  .menu-holder{
    align-items: center;
  }
  