.info {
    color : var(--hti-title);
    font-size: 1.5em;
}
.info-div {
    margin-top: 12%;
}
.info-icon {
    font-size: 3.5em !important;
    color : var(--hti-title)  !important;
}
.info-link {
    color : var(--hti-title)  !important;
    cursor: pointer;
    text-decoration: underline;
    font-size: 1em;
}
.not-found-text {
    color : var(--hti-title)  !important;
    font-size: 1.6rem;
    margin-bottom: 5px !important;
}